// .LoginPage {
//   min-height: 70vh !important;
//   max-width: 1150px !important;
//   margin: 80px auto 20px auto !important;
//   // background: #f2f3f7 0% 0% no-repeat padding-box;
// }

.outer-spinner-div {
  display: flex;
  justify-content: center;
  padding: 175px 0px;
}
